const usingServices = 0;

const NoServices = () => {
  return false;
}

const ServicesDetail = () => {

  // const { allContentfulSiteCopy } = useStaticQuery(query)

  // return (
  //   <StyledServices>
  //     <h3>Our services</h3>

  //     <Grid33>

  //       <div>

  //         <h1 className="servicesIntro">
  //           The what,<p></p>the how <p></p>and the why.
  //           </h1>

  //         <p className="servicesIntro">What is it that makes the property services we put on the table different from any others? It’s simple. You sit at the table with us. You become co-creators with us. Valued members of the team. We are small, and that makes us nimble, with the turning circle of a jetski, rather than a massive cruise liner. We abhor big talk or smalltalk here. We value straight talk. We put our clients front and centre in every conversation. And we work with you, not for you. All that differerentness? That’s the New Vantage advantage.</p>

  //         <div className="stripedBackground"></div>
  //       </div>

  //       <div>

  //         {
  //           allContentfulSiteCopy.edges.map((copy, i) => (
  //             <div id={copy.node.id} key={i}>
  //               <Grid23>

  //                 <hr className="servicesHr" />

  //                 <div>

  //                   <h4>{copy.node.title}</h4>
  //                   <p>{copy.node.summary}</p>
  //                   <div dangerouslySetInnerHTML={{ __html: copy.node.childContentfulSiteCopyCopyTextRichTextNode.childContentfulRichText.html }} />

  //                 </div>
  //               </Grid23>

  //             </div>
  //           ))
  //         }

  //       </div>

  //     </Grid33>

  //   </StyledServices>
  // )
}

export default usingServices === 0 ? NoServices : ServicesDetail