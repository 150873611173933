import React from 'react';

import { connectRefinementList } from 'react-instantsearch-dom';

import { Grid26 } from "site/src/components/css"

const HeroRefinementList = ({ items, refine, searchForItems, type }) => {

  return (
    <div className={`refinementList ${type} `}>
      {
        typeof window !== 'undefined' && (

          <div>
            {/* {
              type === "suburb" && (
                <input
                  type="search"
                  placeholder="Search for suburbs or clusters"
                  className="suburbSearch"
                  onChange={event => searchForItems(event.currentTarget.value)}
                />
              )
            } */}
            <div className="refinementItems">
              {
                items.map((item, i) => (
                  <div className="refinementBox" key={i}>
                    <Grid26>
                      <div>
                        <input type="checkbox" checked={item.isRefined ? "checked" : ""} name={item.label.replace("VacantLand", "Vacant land")} onChange={event => {
                          event.preventDefault();
                          refine(item.value);
                        }} />
                        <label htmlFor={item.label.replace("VacantLand", "Vacant land").replace("Logistics/DC", "New developments")}>{item.label.replace("VacantLand", "Vacant land")}</label>
                      </div>
                      <div className="itemCount">
                        {item.count}
                      </div>
                    </Grid26>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </div>

  )
};

export default connectRefinementList(HeroRefinementList);