import React, { useState, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import { theme } from '../components/css/theme';
import { Burger, Menu, Menu1, Menu1_1, Menu2, Menu2_2, Arrows1, Arrows2, Squares } from '../components';
import { StyledHeader, StyledMenuSide, Grid27, Grid26 } from "./css";

import { useOnClickOutside } from '../hooks';

function Nav({ page }) {

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);

  const node = useRef();
  const node1 = useRef();
  const node2 = useRef();
  const node3 = useRef();
  const node4 = useRef();
  const node5 = useRef();
  const node6 = useRef();

  useOnClickOutside(node, () => setOpen(false));
  useOnClickOutside(node1, () => setOpen1(false));
  useOnClickOutside(node2, () => setOpen2(false));
  useOnClickOutside(node3, () => setOpen3(false));
  useOnClickOutside(node4, () => setOpen4(false));
  useOnClickOutside(node5, () => setOpen5(false));
  useOnClickOutside(node6, () => setOpen6(false));

  //Querying site metadata
  const { site } = useStaticQuery(query)

  return (

    <StyledHeader>

      {
        page === "content" && theme.navStyle !== "other" && (

          <div className="topHeader">

            <Link className="logo" to="/">

              <img loading="lazy" src={process.env.GATSBY_NAV_LOGO} alt={process.env.GATSBY_SITE_NAME} />

            </Link>

          </div>

        )

      }

      {
        theme.navStyle === 'other' && (

          <div className="navBar">

            {/* Desktop menu */}

            <div className="desktopMenu">

              <div>

                <Link className="logo" to="https://www.barrow.co.za">

                  <img loading="lazy" src={process.env.GATSBY_NAV_LOGO} alt={process.env.GATSBY_SITE_NAME} />

                </Link>

              </div>

      </div>
          </div>

        )
      }

    </StyledHeader >

  )
}

export default Nav

const query = graphql`
  query SiteData{
    site {
      siteMetadata {
        siteNavLogo
      }
    }
  }
`