import React from "react";

var formatCurrency = require("cre-plugin/src/components/utils/formatter").formatCurrency;
var formatCurrencyGLA = require("cre-plugin/src/components/utils/formatter").formatCurrencyGLA;
var formatGLA = require("cre-plugin/src/components/utils/formatter").formatGLA;

var priceRange = require("cre-plugin/src/components/utils/priceGlaData").priceRange;
var glaRange = require("cre-plugin/src/components/utils/priceGlaData").glaRange;

const InfoTab = ({ info, open, deal, type, i }) => {

  //Property tabs
  if (type === "property") {

    //To let
    if (deal === "ToLet") {
      return (

        <div className="infoContainer">

          <div className="propertyName">
            <h5>{info[0].property_name}</h5>
          </div>

          {/* <StyledInfoNotch> */}

            <div className="details">
              {/* 
              <span>
                <h6>{info[0].property_category} to let</h6>
              </span>
              <span>
                <h6>{info[0].suburb}</h6>
              </span> */}
              <span>
                <h6>Price:</h6><p> {formatCurrencyGLA(priceRange(info).gross_price_1, priceRange(info).gross_price_2)}</p>
              </span>
              <span>
                <h6>GLA:</h6><p> {formatGLA(glaRange(info).gla_1, glaRange(info).gla_2)}</p>
              </span>
              <span>
                <h6>From:</h6><p> {formatCurrency(priceRange(info).priceFrom)} /month</p>
              </span>
              {/* <span>
                <h6>Units:</h6><p> {info.length > 1 ? info.length + ` units available` : info.length + ` unit available`}</p>
              </span> */}

            </div>

          {/* </StyledInfoNotch> */}

        </div>

      )
    }

    //For sale
    if (deal === "ForSale") {
      return (

        <div className="infoContainer">

          <div className="propertyName">
            <h5>{info.property_name}</h5>
          </div>

          {/* <StyledInfoNotch> */}

            <div className="details">

              {/* <h6>{info.property_category} for sale</h6>

                <h6>{info.suburb}</h6> */}

              <span>
                <h6>Price:</h6><p> {formatCurrency(priceRange([info]).gross_price_1)}</p>
              </span>
              <span>
                <h6>GLA:</h6><p> {formatGLA(glaRange([info]).gla_1)}</p>
              </span>
              <span>
                {/* <h6>Type:</h6><p> {(info.property_type ? info.property_type.charAt(0).toUpperCase() : type.charAt(0).toUpperCase() + info.property_type ? info.property_type.slice(1).replace("_", " ") : type.slice(1)).replace("_", " ")} for sale</p> */}
              </span>

            </div>

          {/* </StyledInfoNotch> */}

        </div>

      )
    }

  }

  if (type === "Portfolio") {
    return (

      <div className="infoContainer">

        <div className="propertyName">
          <h5>{info.property_name}</h5>
        </div>

        {/* <StyledInfoNotch> */}

          <div className="details">

            {/* <h6>{info.property_category} for sale</h6>

              <h6>{info.suburb}</h6> */}

            <span>
            <h6>Category:</h6><p>{info.property_category.replace("VacantLand", "Vacant land")}</p>
            </span>
            <span>
            <h6>Suburb:</h6><p>{info.suburb}</p>
            </span>
            <span>
              {/* <h6>Type:</h6><p> {(info.property_type ? info.property_type.charAt(0).toUpperCase() : type.charAt(0).toUpperCase() + info.property_type ? info.property_type.slice(1).replace("_", " ") : type.slice(1)).replace("_", " ")} for sale</p> */}
            </span>

          </div>

        {/* </StyledInfoNotch> */}

      </div>

    )
  }
}

export default InfoTab