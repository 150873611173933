import styled from 'styled-components';

export const Styled404 = styled.div`

    background: #005395;
    color: white;
    display: grid;
    align-items: center;
    height: 100vh;
    padding-top: 3rem;
    text-align: center;

    h5 {
      font-weight: 300;
    }

    a {
      color: #d61f27;
    }

`
;