import styled from 'styled-components';

export const StyledMenu = styled.li`

  .active {
    background: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryWhite};
  } 

  a {
    font-family: ${({ theme }) => theme.secondaryTypeface};
    display: block;
    padding: 1rem;
    font-size: 0.9rem!important;
    letter-spacing: 0.9px;

    &:hover {
      background: ${({ theme }) => theme.primaryDark};
      color: ${({ theme }) => theme.primaryWhite};
    }
    
  }

  span {
    text-align: center;
  }

`;
