import React, { Fragment } from 'react';
import ImageGallery from 'react-image-gallery';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "react-image-gallery/styles/css/image-gallery.css";

import { VscChevronRight, VscChevronLeft } from "react-icons/vsc";

import { StyledImage } from "../css";

//Custom controls

function renderLeftNav(onClick, disabled) {
 
  return (

    <button
      type="button"
      className='buttonNav left'
      disabled={disabled}
      onClick={onClick}>

      <VscChevronLeft />

    </button>

  )
}

function renderRightNav(onClick, disabled) {
  return (

    <button
      type="button"
      className='buttonNav right'
      disabled={disabled}
      onClick={onClick}>

      <VscChevronRight />

    </button>

  )
}

function getImageFile(data){
  const image = getImage(data);
  return <GatsbyImage image={image} alt=""/>
}

const ImageHitShow = ({ data, title, best_image, type }) => {

  var imageArray = [];
  var fetching = true;

  if (data && data !== null && data.length > 0) {

    if (type === 'development') {
      data.slice(0, 8).forEach((image) => {
        imageArray.push({
          //thumbnail: image.fixed.src,
          original: image.fixed.src,
          originalAlt: title,
          thumbnailAlt: title
        })
        console.log(imageArray);
      })
    } else {
      data.slice(0, 8).forEach((image) => {
        imageArray.push({
          //thumbnail: image.image_path_url,
          original: image.image_path_url.replace("h_600,w_600", "h_242,w_346,f_auto"),
          originalAlt: title,
          thumbnailAlt: title
        })
      })
    }



    fetching = false;

    return (

      <Fragment>

        {
          fetching && imageArray.length !== 0 && typeof window !== 'undefined' && (
            <StyledImage>
              <div className="loader"></div>
            </StyledImage>
          )
        }

        {
          !fetching && imageArray.length !== 0 && typeof window !== 'undefined' && (
            <StyledImage>
              <ImageGallery
                items={imageArray}
                lazyLoad={false}
                showBullets={true}
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails={false}
                showNav={true}
                thumbnailPosition="bottom"
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
              />
            </StyledImage>
          )
        }

      </Fragment>

    )

  } else {
    return (
      <StyledImage>
        <div>
          <img alt={title} src={best_image.replace("h_600,w_600", "h_700,w_1000,f_auto")} />
        </div>
      </StyledImage>
    )
  }


}

export default ImageHitShow