import React, { Fragment } from "react";

import { Link } from "gatsby";

import { theme } from '../css/theme';
import { StyledBreadcrumb, StyledPage } from "../css";

// const { makeBlogTagPath } = require(`blog-plugin/src/components/utils/urlGenerator`)

const Seperator = () => (

  <li>
    <h6>{theme.breadcrumbSeperator}</h6>
  </li>

)

const Breadcrumbs = ({ show, data }) => {

  if (show) {

    if (data.type === "content") {
      //Regular site pages

      return (

        <div>

          <img alt="barrow" style={{ maxWidth: "100%", objectFit: "cover", minHeight: 150 }} src={data.image} />

          <StyledPage>

            <StyledBreadcrumb>

              <div className="breadLine">

                <li>
                  <Link to={`/`} >
                    <h6>Home</h6>
                  </Link>
                </li>

                {
                  data.mainCat && (

                    <Fragment>

                      <Seperator />

                      <li>
                        <Link to={`/${data.mainCatLink}`} >
                          <h6>{data.mainCat}</h6>
                        </Link>
                      </li>


                    </Fragment>

                  )
                }

                {
                  data.title && (

                    <Fragment>

                      <Seperator />

                      <li className="active">
                        <h6>{data.title}</h6>
                      </li>

                    </Fragment>

                  )
                }

              </div>

            </StyledBreadcrumb>

          </StyledPage>

        </div>
      )

    }

    if (data.type === "property") {

      //Property pages

      return (

        <StyledBreadcrumb>

          <div className="breadLine">

            <li>
              <Link to={`/`} >
                <h6>Home</h6>
              </Link>
            </li>

            <Seperator />

            <li>
              <Link to={`/vacancy/search?page=1&sortBy=${data.deal}`} >
                <h6>{data.deal.replace("ToLet", "To let").replace("ForSale", "For sale")}</h6>
              </Link>
            </li>

            <Seperator />

            <li>
              <Link to={`/vacancy/search?page=1&sortBy=${data.deal}&query=&refinementList%5Bproperty_category%5D%5B0%5D=${data.data.property_category}`} >
                <h6>{data.data.property_category.replace("VacantLand", "Vacant land")}</h6>
              </Link>
            </li>

            <Seperator />

            <li className="active">
              <Link to={`/vacancy/search?page=1&sortBy=${data.deal}&query=&refinementList%5Bsuburb_cluster%5D%5B0%5D=${data.data.suburb}&refinementList%5Bproperty_category%5D%5B0%5D=${data.data.property_category}`} >
                <h6>{data.data.suburb}</h6>
              </Link>
            </li>

            <Seperator />

            <li className="active">
              <h6>{data.data.property_name}</h6>
            </li>

          </div>

        </StyledBreadcrumb>
      )

    }

    if (data.type === "portfolio") {

      //Property pages

      return (

        <StyledBreadcrumb>

          <div className="breadLine">

            <li>
              <Link to={`/`} >
                <h6>Home</h6>
              </Link>
            </li>

            <Seperator />

            <li>
              <Link to={`/portfolio/search`} >
                <h6>Portfolio</h6>
              </Link>
            </li>

            <Seperator />

            <li>
              <Link to={`/portfolio/search?page=1&query=&refinementList%5Bproperty_category%5D%5B0%5D=${data.data.property_category}`} >
                <h6>{data.data.property_category.replace("VacantLand", "Vacant land")}</h6>
              </Link>
            </li>

            <Seperator />

            <li className="active">
              <Link to={`/portfolio/search?page=1&squery=&refinementList%5Bsuburb_cluster%5D%5B0%5D=${data.data.suburb}&refinementList%5Bproperty_category%5D%5B0%5D=${data.data.property_category}`} >
                <h6>{data.data.suburb}</h6>
              </Link>
            </li>

            <Seperator />

            <li className="active">
              <h6>{data.data.property_name}</h6>
            </li>

          </div>

        </StyledBreadcrumb>
      )
    }

    if (data.type === "search") {

      //Search pages

      return (

        <div>

          <img alt="barrow" style={{ maxWidth: "100%", objectFit: "cover", minHeight: 150 }} src={data.image} />

          <StyledPage>

            <StyledBreadcrumb>

              <div className="breadLine">

                <li>
                  <Link to={`/`} >
                    <h6>Home</h6>
                  </Link>
                </li>

                <Seperator />

                {
                  !data.secCat && (
                    <li>
                      <h6>{data.mainCat}</h6>
                    </li>
                  )
                }


                {
                  data.secCat && (

                    <Fragment>

                      <li>
                        <Link to={`/portfolio/search`} >
                          <h6>{data.mainCat}</h6>
                        </Link>
                      </li>

                      <Seperator />

                      <li>
                        <h6>{data.secCat}</h6>
                      </li>
                    </Fragment>
                  )

                }

              </div>

            </StyledBreadcrumb>

          </StyledPage>

        </div>
      )
    }

  } else {

    return false;

  }
}

export default Breadcrumbs;