import React from 'react';
import { StyledButton1, StyledButton2, StyledButton3, StyledButton4, StyledButton8 } from '../css';
import { FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import { VscChevronRight } from "react-icons/vsc";

export const Button1 = ({ text1, text2, color }) => (
  <StyledButton1 color={color}>
    <div className="buttonText">
      <span className="bold">{text1}</span><span> {text2}</span>
    </div>
    <div className="buttonSquare">
      <VscChevronRight />
    </div>

  </StyledButton1>
)

export const Button2 = ({ text1, text2, className }) => (
  <StyledButton2 className={className}>
    <div className="buttonText">
      <span className="bold">{text1}</span><span> {text2}</span>
    </div>
    <div className="buttonSquare">
      <VscChevronRight />
    </div>

  </StyledButton2>
)

export const Button3 = ({ text1, text2 }) => (
  <StyledButton3>
    <div className="buttonText">
      <span className="bold">{text1}</span><span> {text2}</span>
    </div>
    <div className="buttonSquare">
      <VscChevronRight />
    </div>

  </StyledButton3>
)

export const Button4 = ({ text1, text2 }) => (
  <StyledButton4>
    <FaMapMarkerAlt />
    <div className="buttonText">
      <div className="bold">{text1}</div>
      <div> {text2}</div>
    </div>

  </StyledButton4>
)

export const Button5 = () => (
  <div className="searchButton">
    <FaSearch />

  </div>
)

export const Button6 = () => (
  <div>
    <button className="basicButton" aria-label="submit" type="submit">Send</button>
  </div>
)

export const Button7 = ({ text1 }) => (
  <div>
    <button className="basicButton">{text1}</button>
  </div>
)

export const Button8 = ({ text1, text2, className }) => (

  <button aria-label="submit" type="submit" style={{ border: "none", cursor: "pointer" }}><VscChevronRight /></button>

)