import styled from 'styled-components';

export const StyledSearch = styled.div`

  margin-top: 3rem;

  .primarySearch {
    
  }

  .primarySearch .container {
    max-width: 1024px;
    margin: 0 auto;
  }

  .searchBox.hero {
    width: 400px;
    max-width: 100%;
    margin: 1rem auto 0 auto;
  }

  .searchBox.hero.mobile input {

    color: ${({ theme }) => theme.primaryDark};
  }

  .searchBox input {
    letter-spacing: 1px;
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid;
    margin-bottom: 0;
  }


  #searchBox::placeholder, #searchBox::-webkit-input-placeholder {

  }

  #multiSelect {
    margin-bottom: 1rem;
  }

  .searchBox.hero.mobile i#searchBox::placeholder, .searchBox.hero.mobile #searchBox::-webkit-input-placeholder, input::placeholder, input::-webkit-input-placeholder {
    color: #c9c9c9;
  }

  .chip {
    background: ${({ theme }) => theme.primaryDark};
  }  

  #multiSelect {
    font-family: ${({ theme }) => theme.secondaryTypeface};
  }

  #multiSelect div {
    padding: 0.5rem;
    border: none;
  }

  #multiSelect input {
    padding: 0;
  }

  .loader {
    border: 5px solid ${({ theme }) => theme.primaryWhite}; /* Light grey */
    border-top: 5px solid ${({ theme }) => theme.primaryDark}; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 2rem auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .dealtrue {
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryWhite};
    padding: 1rem;
    border: none;

  }

  .dealfalse {
    cursor: pointer;
    padding: 1rem;
    border: none;
  }

  .dealBtnLeft {
    margin: 0 1rem 0 0;
  }

  .searchFilters {
    background-color: ${({ theme }) => theme.primaryWhite};
  }

  .searchFilters button {
    padding: 1rem;
    border: none;
    width: 100%;
  }

  .filterToggle, .filterToggleClose, .searchBox.hero.mobile {
    display: none;   
  }

  .filterContainer.true .filterToggleClose, .filterContainer.true .searchBox.hero.mobile {
    display: block;
  }

  .filterContainer.true .mainSearchButton {
    border: none;
    padding: 1rem;
  }

  .itemCount {
    text-align: right;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0 0 0.5rem 0;
  }

  .primaryStats p {
    color: black;
    text-align: left;
    text-transform: none;
  }

  .mainSearchButton {
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryWhite};
    &:hover {
      color: ${({ theme }) => theme.primaryDark};
      background-color: ${({ theme }) => theme.primaryWhite};
    }
  }

  .refinementList {
    margin-bottom: 2rem;
    padding-top: 2rem;
  }

  .refinementList.suburb {
    border-top: 1px solid black;
  }

  .refinementBox {
    margin-bottom: 1rem;
  }

  .clearRefinements{
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }

  .clearRefinements:disabled {
    color: #c9c9c9;
  }

  .propertyHitDetail {
    position: relative;
  }

  .filterContainer.true .filterBtnContainer {
    display: none;
  }

  @media(max-width: 666px) {
    .searchFilters {
      padding: 1.5rem;
      overflow: auto;
    }
  }

  @media(max-width: 768px) {

    .filterContainer.false {
       display: none;
    }

    .filterContainer.true {
      position: fixed;
      max-height: 100vh;
      overflow-y: auto;
      top: 0;
      left: 0;
      z-index: 9999999;
      background-color: ${({ theme }) => theme.primaryWhite};
      width: 100%;
      height: 100%;
      padding: 2rem 1rem;
    }

    .filterContainer.true .dealFilters {
      text-align: center;
    }

    .filterBtnContainer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: white;
      display: table;
      text-align: center;
      padding: 1rem;
      z-index: 999999;
    }

    .filterToggle {
      background-color: #001526;
      border: none!important;
      color: #ffffff;
      border-radius: 4px;
      padding: 1rem;
      width: 120px;
      display: table-row;
      margin: 0;
    }
  }

  @media(min-width: 769px) and (max-width: 1024px){
    .propertyHitDetail a {
      display: block;
      min-height: 60px;
    }
  }

  svg {
    vertical-align: text-top;
  }

  

`;
