import React from 'react';

import { FaRegEnvelope, FaWhatsapp } from 'react-icons/fa';
import { WhatsappShareButton, EmailShareButton } from "react-share";

import { Brochure } from "cre-plugin/src/components/propertyComponents";

import { StyledShare } from "../css"

const Share = ({ socialConfig }) => {

  return (
    <StyledShare>

      <h5>Share</h5>

      <div className="shareButton">
        <WhatsappShareButton url={socialConfig.config.url} > 
       
          <FaWhatsapp />
          <p>WhatsApp</p>
        </WhatsappShareButton>
      </div>
      <div className="shareButton">
        <EmailShareButton url={socialConfig.config.url} subject={`Check out ${socialConfig.config.title}`} body={`I think you should have a look at this property`} separator="---">
         
          <FaRegEnvelope />
          <p>Email</p>
        </EmailShareButton>
      </div>
      
      <Brochure pdf={socialConfig.config.pdf} data={socialConfig.config.data} type={socialConfig.config.type} />

    </StyledShare>
  )
}


export default Share;