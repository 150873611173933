import React from 'react';

import { event } from "../";

import { StyledForm, Grid64 } from "../css";

import { Button8 } from "../";

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

//const event = require("../").GTAG;

class ContactForm1 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.state.email === "") {

      alert("Please fill in a valid email address");

    } else {

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': e.target.getAttribute('name'),
          ...this.state,
        }),
      })
        .then(() => alert("Message successfully sent!"))
        .catch(error => alert(error));

      event({
        category: 'Connect',
        action: 'connect_form_submission',
        label: this.props.data
      });
    }

  };

  handleChange = e => this.setState({
    [e.target.name]: e.target.value
  });

  render() {

    const { email } = this.state;

    return (

        <form

          name="connect"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <Grid64>
            <div>
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
                </label>
              </p>
              <input type="hidden" name="form-name" value="connect" />
              <input aria-label="bot" type="hidden" name="bot-field" />

              <input style={{ width: "100%", maxHeight: 50, margin: "2rem 0", background: "#222c34", border: "none", paddingBottom: "1rem", borderBottom: "solid 1px white", color: "white" }} aria-label="email" type="email" name="email" id="email" placeholder="Email" value={email} onChange={this.handleChange} placeholder="Your email address" />
            </div>
            <Button8 />
          </Grid64>

        </form>


    )
  }
}

export default ContactForm1