import React from "react";
// import { Link } from "gatsby";

import { Grid38 } from "site/src/components/css";

// var slugify = require('slugify');

// const { makePersonPagePath } = require("people-plugin/src/components/utils/urlGenerator");
const { formatTelephone } = require("site/src/components/utils/telFormatter");

export default function Contacts({ contacts }) {

  if (contacts.length === 0) {

    return (

      <div className="contactsBlock">
        <h5>Contact us</h5>
        <Grid38>

          <div>

            <div className="contactImage">
              <img loading="lazy" src={process.env.GATSBY_COMPANY_LOGO_SQUARE} alt={process.env.GATSBY_COMPANY_NAME} />
            </div>

            <div className="contactDetails">
              <h6>{process.env.GATSBY_COMPANY_NAME}</h6>
              <p><a href={`tel:${process.env.GATSBY_COMPANY_TEL_1}`}>{formatTelephone(process.env.GATSBY_COMPANY_TEL_1)}</a></p>
              {/* <p><a href={`tel:${process.env.GATSBY_COMPANY_TEL_2}`}>{formatTelephone(process.env.GATSBY_COMPANY_TEL_2)}</a></p> */}
              {/* <p><a href={`mailto:${process.env.GATSBY_COMPANY_EMAIL}`}>{process.env.GATSBY_COMPANY_EMAIL}</a></p> */}
            </div>



          </div>

        </Grid38>

      </div>

    )
  } else {

    return (

      <div className="contactsBlock">
        <h5>Contact us</h5>
        <Grid38>

          {
            contacts.map((contact, i) => {

              // var name_slug = slugify(contact.name, {
              //   replacement: '-',
              //   remove: /[*+~.()'"!:@_,]/g,
              //   lower: true
              // });

              return (
                <div key={i}>
                  <div className="contactImage">
                    <img loading="lazy" src={contact.image.replace("c_fill", "c_fill,g_face")} alt={contact.name} />
                  </div>



                  <div className="contactDetails">
                    <h5>{contact.name}</h5>
                    <p>{contact.role}</p>
                    <p><a href={`tel:${contact.cell_number}`}>{formatTelephone(contact.cell_number)}</a></p>
                    <p><a href={`mailto:${contact.email}`}>{contact.email}</a></p>
                  </div>




                </div>
              )
            })
          }

        </Grid38>
      </div>


    )
  }
}