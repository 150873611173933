import styled from 'styled-components';

export const StyledPage = styled.div`

  position: relative;
  max-width: 1200px;
  margin: 0 auto 4rem auto;
  padding: 0.5rem 1rem 0 1rem;

  @media(max-width: 667px){
    margin: 0 auto 1rem auto;
  }

  @media(max-width: 1024px) and (min-width: 737px){ 
    padding: 1rem 2rem 0 2rem;
  }

  @media(max-width: 736px){ 
    padding: 1rem 1rem 0 1rem;
  }

  .gatsby-image-wrapper {
    height: 300px;
  }

`;
