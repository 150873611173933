import styled from 'styled-components';

export const StyledProperty = styled.div`

  margin-top: 5rem;

  .infoBlock {

  }

  .propertyHeroSearch {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
  }

  .brochureButton {
    &:hover {
      background-color: ${({ theme }) => theme.primaryLight};
      color: ${({ theme }) => theme.primaryDark};
    }
  }

  .unitGrid, .contactsBlock {
    margin-top: 2rem;
  }

  .unitGrid h6 {
    margin-bottom: 1rem;
  }

  .unitGrid h5 {
    margin-top: 0.5rem;
  }

  .read-more-less {
    font-weight: 400;
    display: block;
    margin-top: 10px;
    color: #96979a;
  }

  .app .propertyTitle {
    margin-bottom: 0;
  }

  .searchBox.hero {
    margin: 3rem auto;
  }

  .propertyTitle h1 {
    line-height: 100%;
    margin-top: 0;
  }

`;
