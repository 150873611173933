import React from "react";
import { FaLinkedin, FaFacebookSquare, FaInstagram } from 'react-icons/fa';

import { SEOFooter, ContactForm1 } from ".";

import { StyledFooter, StyledPage, Grid24, Grid30, Grid64 } from "./css";
import { Button8 } from "../components";

// import ReviewData from "reviews-plugin/src/components/reviews";

const { formatTelephone } = require("./utils/telFormatter");

export default function Footer() {

  return (

    <StyledFooter>

      <div className="topFooter">

        <StyledPage>

          <Grid24>
            <h3 style={{ textAlign: "left", marginTop: 0 }}>The group is family-owned and managed with the 5th generation currently at the helm</h3>
            <p style={{ color: "#96979a" }}>Barrow Properties is the property development and investment arm of the Barrow Group. Barrow Properties focuses primarily on residential and commercial development in Johannesburg. Barrow Properties is actively involved in the management of the commercial properties and prides itself in the service that it provides to its tenants.</p>
          </Grid24>

        </StyledPage>

      </div>

      <div className="middleFooter">

        <Grid30>

          <div>
            <h2>Connect with us.</h2>

            {/* <span style={{ margin: 0 }}>
              <p style={{ margin: "0 0 2px 0", textAlign: "left" }}>{process.env.GATSBY_COMPANY_EMAIL}</p>
            </span> */}

            <span style={{ margin: 0 }}>
              <p style={{ margin: "0 0 2px 0", textAlign: "left" }}>101 Central Street, Houghton, Johannesburg</p>
            </span>

            <span style={{ margin: 0 }}>
              <p style={{ margin: "0 0 2px 0", textAlign: "left" }}>{formatTelephone(process.env.GATSBY_COMPANY_TEL_1)}</p>
            </span>
            <div className="socials" style={{ marginTop: "1rem" }}>

              <a aria-label="facebook" style={{ marginRight: "1rem" }} href={process.env.GATSBY_FACEBOOK_PAGE} rel="noreferrer" target="_blank"><FaFacebookSquare /></a>
              <a aria-label="linkedin" style={{ marginRight: "1rem" }} href={process.env.GATSBY_LINKEDIN_PAGE} rel="noreferrer" target="_blank"><FaLinkedin /></a>
              <a aria-label="instagram" href={process.env.GATSBY_INSTAGRAM_PAGE} rel="noreferrer" target="_blank"><FaInstagram /></a>

            </div>
          </div>

          <div></div>

          {/* <form>
        
              <ContactForm1 />
            
          </form> */}

          <img src={process.env.GATSBY_NAV_LOGO} width="200px" style={{ margin: "auto" }} alt="barrow" />

        </Grid30>

      </div>

      <div className="bottomFooter">

        <div className="d9Signature">
          <a href="https://d9cre.com" title="Website built by D9 CRE" target="_blank" rel="noreferrer"><img src={"https://res.cloudinary.com/d9-cre/image/upload/v1598599829/vhe6cvjohpmxr0qqbokg.svg"} alt="D9 CRE" width="30px" /></a>
        </div>
      </div>

      <SEOFooter />

    </StyledFooter>

  )
}