import styled from 'styled-components';

export const StyledSnap = styled.div`

  scroll-snap-type: x mandatory;	
	display: flex;
	-webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  gap: 1rem;
  
  .header2 {
    
  }

  .triangle {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -15px;
    left: 20px;
    background-color: white;
    z-index: 999;
    transform: rotate(45deg);
  }

  .child.main {
    &:after {
      background: linear-gradient(
        90.26deg,
        rgba(3, 113, 227, 0.9) 17.75%,
        rgba(238, 169, 64, 0.61) 99.89%
      );
    }
  }
  .child {
    border-right: 1px solid white;
    min-width: calc(33.33% - 1rem);
    position: relative;
    display: grid;
    grid-template-rows: 40% 9% 51%;
  }

  @media(max-width: 736px){
    .child.main {

    }
    .child {
      min-width: 75vw;
      width: 85vw;
      max-width: 100%;
      height: 100vh;
      scroll-snap-align: center;
    }
  }

  .innerDetail {
    padding: 1rem;
  }

  .articleButton {

  }

`;
