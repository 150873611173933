import React from "react";
import { Link } from "gatsby";

import { PropertyHit } from "../searchComponents";

import { Maps } from "../propertyComponents";

import { Grid12 } from "site/src/components/css";

const { makePropertyPagePath } = require("../utils/urlGenerator");

var _ = require('lodash');

function RelatedProperty({ property, deal }) {

  var propertyGroup = [];

  if (deal === "ToLet") {

    propertyGroup = Object.values(_.groupBy(property, 'property_gmaven_key'));

  } else {

    propertyGroup = property;

  }
  return (
    <div className="mediaGrid">
      {
        propertyGroup.length > 0 && propertyGroup.slice(0, 3).map((property, i) => {

          const DealData = () => {
            //To let

            if (deal === "ToLet") {

              return (

                <div key={i} className="item">

                  <Link to={makePropertyPagePath(property[0], "ToLet", "property")} >

                    <Grid12 style={{ alignItems: "bottom" }}>

                      <div>

                        <h3 className="propertyTitle">{property[0].property_name}</h3>

                        <p className="propertyDescription" style={{ color: "#96979a" }}>{property[0].marketing.property_marketing_description && property[0].marketing.property_marketing_description.length > 300 ? property[0].marketing.property_marketing_description.substring(0, 100) + "..." : property[0].marketing.property_marketing_description}</p>

                        <div className="propertyMap">
                          <Maps lat={property[0].latitude} lng={property[0].longitude} />
                        </div>

                      </div>

                      <div className="propertyImages">
                        <img style={{ width: 300, objectFit: "contain" }} src={property[0].best_image ? property[0].best_image.replace("h_600,w_600", "h_300,w_500") : process.env.GATSBY_COMPANY_LOGO_SQUARE} alt={property[0].property_name} />
                      </div>

                    </Grid12>

                  </Link>

                </div>
              )
            }

            //For sale property
            if (deal === "ForSale") {

              return (

                <div key={i} className="item">

                  <Link to={makePropertyPagePath(property, "ForSale", property.property_type)} >

                    <Grid12>

                      <div>

                        <h3 className="propertyTitle">{property.property_name}</h3>

                        <p className="propertyDescription" style={{ color: "#96979a" }}>{property.marketing.property_marketing_description && property.marketing.property_marketing_description.length > 300 ? property.marketing.property_marketing_description.substring(0, 100) + "..." : property.marketing.property_marketing_description}</p>

                        <div className="propertyMap">
                          <Maps lat={property.latitude} lng={property.longitude} />
                        </div>

                      </div>

                      <div className="propertyImages">
                        <img style={{ width: 300, objectFit: "contain" }} src={property.best_image ? property.best_image.replace("h_600,w_600", "h_300,w_500") : process.env.GATSBY_COMPANY_LOGO_SQUARE} alt={property.property_name} />
                      </div>

                    </Grid12>

                  </Link>

                </div>
              )
            }
          }

          return (
            <div key={i}>
              <DealData />
            </div>
          )
        })

      }
    </div>
  )
}

export default RelatedProperty