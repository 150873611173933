import React, { Fragment } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import { VscChevronRight, VscChevronLeft } from "react-icons/vsc";

import { StyledImage } from "../css";

//Custom controls

function renderLeftNav(onClick, disabled) {
  return (

    <button
      type="button"
      className='buttonNav left'
      disabled={disabled}
      onClick={onClick}>

      <VscChevronLeft />

    </button>

  )
}

function renderRightNav(onClick, disabled) {
  return (

    <button
      type="button"
      className='buttonNav right'
      disabled={disabled}
      onClick={onClick}>

      <VscChevronRight />

    </button>

  )
}

const ImageShow = ({ data, title, type, open }) => {

  var imageArray = [];
  var fetching = true;

  if (data.length > 0) {

    data.forEach((image) => {
      imageArray.push({
        thumbnail: image.image_path_url,
        original: image.image_path_url.replace("h_600,w_600", "c_fill,h_600,w_1000,f_auto"),
        originalAlt: title,
        thumbnailAlt: title
      })
    })

    fetching = false;

  }

  return (

    <Fragment>

      {
        fetching && ( type === "exterior" || type === "aerial") && imageArray.length !== 0 && open === 0 && typeof window !== 'undefined' && (
          <StyledImage>
            <div className="loader"></div>
          </StyledImage>
        )
      }

      {
        !fetching && ( type === "exterior" || type === "aerial") && imageArray.length !== 0 && open === 0 && typeof window !== 'undefined' && (
          <StyledImage>
            <ImageGallery
              items={imageArray}
              lazyLoad={false}
              showBullets={true}
              showFullscreenButton={true}
              showPlayButton={false}
              showThumbnails={false}
              showNav={true}
              thumbnailPosition="bottom"
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
            />
          </StyledImage>
        )
      }

      {
        fetching && type === "interior" && imageArray.length !== 0 && open === 1 && typeof window !== 'undefined' && (
          <StyledImage>
            <div className="loader"></div>
          </StyledImage>
        )
      }

      {
        !fetching && type === "interior" && imageArray.length !== 0 && open === 1 && typeof window !== 'undefined' && (
          <StyledImage>
            <ImageGallery
              items={imageArray}
              lazyLoad={false}
              showBullets={true}
              showFullscreenButton={true}
              showPlayButton={false}
              showThumbnails={false}
              showNav={true}
              thumbnailPosition="bottom"
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
            />
          </StyledImage>
        )
      }

      {
        fetching && type === "floor_plan" && imageArray.length !== 0 && open === 2 && typeof window !== 'undefined' && (
          <StyledImage>
            <div className="loader"></div>
          </StyledImage>
        )
      }

      {
        !fetching && type === "floor_plan" && imageArray.length !== 0 && open === 2 && typeof window !== 'undefined' && (
          <StyledImage>
            <ImageGallery
              items={imageArray}
              lazyLoad={false}
              showBullets={true}
              showFullscreenButton={true}
              showPlayButton={false}
              showThumbnails={false}
              showNav={true}
              thumbnailPosition="bottom"
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
            />
          </StyledImage>
        )
      }

    </Fragment>

  )
}

export default ImageShow