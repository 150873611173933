import React from "react";
import { Link } from "gatsby";

import { Grid22 } from "site/src/components/css";

const { makePropertyPagePath } = require(`../utils/urlGenerator`)
var formatCurrencyGLA = require("../utils/formatter").formatCurrencyGLA
var formatGLA = require("../utils/formatter").formatGLA

export default function Units({ units }) {

  return (
    <div className="unitGrid" id="units">
      <h5>Units available</h5>
      <Grid22>
        {
          units.map((unit, i) => {
            return (
              <div key={i}>

                <img style={{ width: "100%" }} src={unit.unit_images && unit.unit_images.length > 0 ? unit.unit_images[0].image_path_url : unit.best_image} alt={unit.unit_id} />
                <h5><Link to={makePropertyPagePath(unit, "ToLet", "property_unit")} key={unit.gmaven_mapped_key}>{unit.unit_id}</Link></h5>
                <p>GLA: {formatGLA(unit.max_gla)}</p>
                <p>Gross rental: {formatCurrencyGLA(unit.gross_price)}</p>
                <p>Net rental: {formatCurrencyGLA(unit.net_price)}</p>
              </div>
            )
          })

        }
      </Grid22>
    </div>
  )
}