import React, { Fragment } from "react";
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../components/css/global';
import { theme } from '../components/css/theme';

import { Header, Footer } from ".";

export default function Container({ children, page = "content" }) {

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyles />
        <Header page={page} />
        {/* <PageTitle title={breadData} /> */}
        {/* <Breadcrumb show={breadcrumbs} data={breadData} /> */}
        {children}
        <Footer />
      </Fragment>
    </ThemeProvider>
  )
}