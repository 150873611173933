import styled from 'styled-components';

export const StyledForm = styled.div`

border: 1px solid black;
padding: 1rem;
margin-top: 2rem;

  form {
    margin-top: 1rem;
  }



  .formInputBox {
    width: 49.5%;
    display: inline-block;
    padding: 1rem;
  }

  h3 {
    text-align: left;
    font-weight: 300;
    font-size: 1.2rem;
    margin: 0;
    text-transform: initial;
  }

  .formInputBox.odd {
    margin-right: 1%;
  }

  span {
    color: black;;
  }

  .contactDetails p {
    margin: 3px 0;

  }

  .contactDetails > * {
    vertical-align: bottom;
  }

  .contactDetails h5 {
    margin-top: 1rem;
  }

  input, textarea {
    background-color: #eee;
    color: black;
    display: block;
    border: none;
    padding: 1rem;
    margin: 0 0 5px 0;
    width: 100%;
  }

  .contactImage {
    max-width: 150px;
    -webkit-clip-path: polygon(
      0% 15%,     /* top left */
      15% 0%,     /* top left */
      100% 0%,    /* top right */
      100% 0%,   /* top right */
      100% 100%,  /* bottom right */
      100% 100%,  /* bottom right */
      0% 100%,   /* bottom left */
      0 100%      /* bottom left */
    );
    
    clip-path: polygon(
      0% 15%,     /* top left */
      15% 0%,     /* top left */
      100% 0%,    /* top right */
      100% 0%,   /* top right */
      100% 100%,  /* bottom right */
      100% 100%,  /* bottom right */
      0% 100%,   /* bottom left */
      0 100%      /* bottom left */
    );
  }
  
  .contactImage img {
    width: 100%;
  }

  .submitButton {
    text-align: right;
  }

  .submitButton button {

  }
  
`;