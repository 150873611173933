import styled from 'styled-components';

export const StyledSnapScroll2 = styled.div`
  :root {
    --gutter: 20px;
  }

  .app {
    padding: 20px 0;
    display: grid;
    grid-gap: 20px 0;
    grid-template-columns: 20px 1fr 20px;
    align-content: start;
  }
  
  .app > * {
    grid-column: 3 / -2;
  }
  
  .app > .full {
    grid-column: 1 / -1;
  }

  .mediaGrid {
    display: grid;
    grid-gap: calc(20px / 3);
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: minmax(150px, 1fr);
    
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    padding-bottom: calc(.75 * 20px);
    margin-bottom: calc(-.25 * 20px);
    align-items: end;
  }

  .hide-scroll {
    overflow-y: hidden;
    margin-bottom: calc(-.1 * 20px);
  }

  .app {
    overflow-y: hidden;
  }

  .mediaGrid > div, .item {
    scroll-snap-align: center;
    padding: calc(20px / 2 * 1.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 8px;
  }

  @media(min-width: 1200px) {
    .mediaGrid {
      
    }
  }

  .articleButton {
  }

  img {
    
  }

`;