import styled from 'styled-components';

export const StyledHeroSearch = styled.div`

margin-bottom: 1rem;

div {
  background-color: transparent;
  border: none;  
  max-width: 600px;
}

p {
  color: black;
  opacity: 0.7;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.7rem;
  margin: 0;
}

ul {
  background: white;
  border: none;
  list-style: none;
  width: 200px;
  color: ${({theme}) => theme.primaryDark};
}

button {
  display: inline-block;
  padding: 10px 5px;
  background: transparent;
  text-transform: uppercase;
  color: ${({theme}) => theme.primaryDark};
  letter-spacing: 1px;
  border: none;
  font-size: 1rem;

  &:hover {
    color: ${({theme}) => theme.primaryWhite};
  }
}

.searchButton {
  border: 1px solid black;
  padding: 1rem;
  text-align: center;
  width: 4rem;
  margin-left: 1rem;
}

i {
  display: none;
}

.searchBox {
  display: grid;
  grid-template-columns: 5fr 1fr;
  max-width: 600px;
  align-items: center;
  margin: 0 auto;
}

#searchBox {
  border: none;
  border-bottom: 1px solid black;
  background: transparent;
  color: black;
  letter-spacing: 1px;
  padding: 0.5rem;
  width: 100%;
  margin: 1rem auto 0 auto;
}

#multiSelect:after, #multiSelect-deal:after, input#multiSelect-deal_input:after {
  content:"";
  position: absolute;
  border-top: 2px solid white;
  top: 12px;
  right: -33px;
  width:20px; 
  transform: rotate(135deg);
  transform-origin: 0% 0%;
}



#searchBox::placeholder, #searchBox::-webkit-input-placeholder {

  color: black;

}

#multiSelect, #multiSelect_input, input:disabled {
  width: 100%;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#multiSelect span.css-1okebmr-indicatorSeparator, #multiSelect .css-tlfecz-indicatorContainer, #multiSelect .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

#multiSelect_input::placeholder, #multiSelect_input::-webkit-input-placeholder, #multiSelect-deal_input::placeholder, #multiSelect-deal_input::-webkit-input-placeholder {
  color: ${({theme}) => theme.primaryWhite};
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

#multiSelect_input, #multiSelect-deal_input {
  text-align: center;
  margin: 0;
  overflow: hidden;
  width: 100%;
  padding: 5px;
}

#multiSelect_input:focus, #searchBox:focus {
  background-color: transparent;
  color: black;
  border-radius: 4px;
}

#multiSelect-deal i {
  display: none;
}

@media(max-width: 576px){
  .heroFilters #multiSelect-deal, .heroFilters #multiSelect {
    display: none;
  }
  .heroFilters button {
    margin: 1rem 0 1rem 0;
    border-radius: 4px;
    background: ${({theme}) => theme.primaryDark};
    color: ${({theme}) => theme.primaryLight};
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }
}

@media(max-width: 992px){
  #multiSelect:after, #multiSelect-deal:after {
    display: none;
  }
}

.loader {
  border: 5px solid ${({ theme }) => theme.primaryWhite}; /* Light grey */
  border-top: 5px solid ${({ theme }) => theme.primaryDark}; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 2rem auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

`;
