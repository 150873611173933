import React from 'react';

import { StyledToggle } from '../css';

const Toggle = ({ open, setOpen }) => {
  return (
    <StyledToggle>
      <div>
        <h5 style={{ marginBottom: 10 }}>{open ? <span style={{ color: "#4a7c9e" }}>Properties</span> : <span style={{ color: "#eb4034" }}>Construction</span>}</h5>
        <h6 onKeyDown={(e) => { e.preventDefault(); setOpen(!open); }} onClick={(e) => { e.preventDefault(); setOpen(!open); }}>{open ? <span style={{ color: "#eb4034" }}><span className="viewBtn">View</span>Construction</span> : <span style={{ color: "#4a7c9e" }}><span className="viewBtn">View</span>Properties</span>}   </h6>
        {/* <FaRandom /> */}
      </div>
    </StyledToggle>
  )
}

export default Toggle