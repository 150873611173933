import React from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { GeneralFeatures, Features } from "cre-plugin/src/components/propertyComponents";
//import { StyledDetailTab } from "../css";

export default ({ property, category }) => (
  <div>

    <hr />
    <h4>Features</h4>
    <Tabs>
      <TabList>
        <Tab>General</Tab>
        <Tab>Specific</Tab>
        <Tab>Security</Tab>
      </TabList>

      <TabPanel>
        <GeneralFeatures property={property[0]} />
      </TabPanel>
      <TabPanel>
        <Features property={property[0]} category={category} />
      </TabPanel>

      {/* <div>

      <Parking property={property[0]} parking={property[0].parking.property_parking} />

    </div> */}
    </Tabs>
  </div>
);