import styled from 'styled-components';

export const StyledFeatured = styled.div`

  p {
    color: #96979a;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  h5 {
    margin: 0;
  }

  .propertyHitDetail {
    position: relative;
    height: 100%;
  }

  @media(max-width: 768px){
    .propertyHitDetail a {
      display: block;
      min-height: 60px;
    }
  }

  .propertySummary {
    
  }

  .propertyHitButton {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
  }

`;