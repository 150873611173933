import React from 'react';
import { Link } from "gatsby";

import { Button1 } from "site/src/components";

const { makePropertyPagePath } = require("../utils/urlGenerator");

var formatCurrencyGLA = require("../utils/formatter").formatCurrencyGLA;
var formatCurrency = require("../utils/formatter").formatCurrency;
var formatGLA = require("../utils/formatter").formatGLA;

var priceRange = require("../utils/priceGlaData").priceRange;
var glaRange = require("../utils/priceGlaData").glaRange;


function PropertyHit({ hitData, type, i, featured, toShow }) {

  var property = hitData;

  if (type === "ToLet") {

    return (

      <div>

        <div className="propertyHitDetail" key={i}>

          <Link to={makePropertyPagePath(property[0], "ToLet", "property")} >

            <h6 style={{ color: "#96979a" }}>{property[0].property_category} to let</h6>
            <h2>{property[0].property_name}</h2>

            <p style={{ color: "#96979a" }}>{property[0].street_address}, {property[0].suburb}</p>
            <p style={{ color: "#96979a" }}>{property[0].city}</p>

            <p style={{ color: "#96979a" }}>Price: {formatCurrencyGLA(priceRange(property).gross_price_1, priceRange(property).gross_price_2)}</p>
            <p style={{ color: "#96979a" }}>GLA: {formatGLA(glaRange(property).gla_1, glaRange(property).gla_2)} </p>
            <p style={{ color: "#96979a" }}>Units: {property.length}</p>

            <Button1 text1="Explore" text2="property" color="#005395" />

          </Link>

        </div>

      </div>

    )
  }
  if (type === "ForSale") {
    return (

      <div>

        <div className="propertyHitDetail" key={i}>

          <Link to={makePropertyPagePath(property, "ForSale", property.property_type)} key={i}>

            <h6 style={{ color: "#96979a" }}>{property.property_category} for sale</h6>
            <h2>{property.property_name}</h2>

            <p style={{ color: "#96979a" }}>{property.street_address}, {property.suburb}</p>
            <p style={{ color: "#96979a" }}>{property.city}</p>

            <p style={{ color: "#96979a" }}>Price: {formatCurrency(property.gross_price)}</p>
            <p style={{ color: "#96979a" }}>GLA: {formatGLA(property.min_gla)} </p>

            <Button1 text1="Explore" text2="property" color="#005395" />

          </Link>

        </div>

      </div >

    )
  }
  if (type === "development") {
    
    var nf = new Intl.NumberFormat()
    return (

      <div>

        <div className="propertyHitDetail" key={i}>

          <h6 style={{ color: "#96979a" }}>{property.tags[0]}</h6>
          <h2>{property.title}</h2>

          <p style={{ color: "#96979a" }}>{property.address}</p>

          {
            (toShow === "recent" || featured) && (
              <div>
                <p className="propertySummary" style={{ color: "#96979a", marginBottom: 3, marginTop: "1.5rem" }}>Amount: R{nf.format(property.price)}</p>
                <p className="propertySummary" style={{ color: "#96979a", marginBottom: 3 }}>Client: {property.client}</p>
                <p className="propertySummary" style={{ color: "#96979a", marginBottom: 3 }}>Architect: {property.architect}</p>
                <p style={{ color: "#96979a", marginBottom: 3, marginTop: "1.5rem" }}>Completed: {property.summary}</p>
              </div>
            )
          }

          {
            toShow === "current" && (
              <div>
                <p className="propertySummary" style={{ color: "#96979a", marginBottom: 3, marginTop: "1.5rem" }}>Amount: R{nf.format(property.price)}</p>
                <p className="propertySummary" style={{ color: "#96979a", marginBottom: 3 }}>Client: {property.client}</p>
                <p className="propertySummary" style={{ color: "#96979a", marginBottom: 3 }}>Architect: {property.architect}</p>
                {/* <p style={{ color: "#96979a", marginBottom: 3, marginTop: "1.5rem" }}>Completed: {property.summary}</p> */}
              </div>
            )
          }

          {
            toShow === "key" && (
              <div>
                <p className="propertySummary" style={{ color: "#96979a", marginBottom: 3, marginTop: "1.5rem" }}>Area: {property.area}</p>
                {/*<p className="propertySummary" style={{ color: "#96979a", marginBottom: 3 }}>Client: {property.client}</p>
                <p className="propertySummary" style={{ color: "#96979a", marginBottom: 3 }}>Architect: {property.architect}</p> */}
              </div>
            )
          }

        </div>

      </div >

    )
  }

}

export default PropertyHit