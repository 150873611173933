import styled from 'styled-components';

export const StyledFooter = styled.div`
  clear: both;
  position: relative;
  width: 100%;
  margin-top: 3rem;

  h3 {
    text-align: center;
    margin-bottom: 2rem;
  }

  h4 {
    margin: 0;
  }

  h6 {
    text-align: center;
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
  }

  .reviewFooter {
    height: 350px;
    margin: 2rem 0;
  }

  .topFooter{
    background-color: ${({ theme }) => theme.primaryWhite };
    margin: 2em 0 4rem 0;
  }
  
  .middleFooter {
    background-color: #222c34;
    color: white;
  }

  @media(max-width: 667px){
    .middleFooter {
      padding: 1rem;
    }
  }
  
  .bottomFooter {
    background-color: #222c34;
    color: white;
    text-align: center;
    margin: 0;
    padding: 1rem;
  }

  .bottomFooter h6 {
    color: white;
  }

  .topFooter p {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .middleFooter div, .bottomFooter div {
    margin-top: 0;
    margin-bottom: 0;  
  }

  .middleFooter h1 {
    margin: 0;
    padding: 1rem 0;
  }

  .middleFooter h3 {
    color: ${({ theme }) => theme.primaryWhite };
    cursor: pointer;
    
  }

  .middleFooter span {
    margin: 2rem;
    width: 2em
  }

  .middleFooter button {
    border: 1px solid ${({ theme }) => theme.primaryWhite };
    color: ${({ theme }) => theme.primaryWhite };
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    margin: 2rem auto;

    &:hover {
      background-color: ${({ theme }) => theme.primaryLight };
      color: ${({ theme }) => theme.primaryDark };
    }
  }

  .bottomFooter p {
    font-size: 0.8rem;
    font-style: italic;
    margin-top: 0;
    text-align: center;
  }

  .bottomBanner .awssld__content div {
    height: 100%;
  }

  a {
    color: ${({ theme }) => theme.primaryWhite };
  }

  svg {
    font-size: 2rem;

    &:hover {
      color: ${({ theme }) => theme.secondaryDark };
    }
  }


  @media(max-width:576px){
    .banner.bottom .awssld__wrapper {
      height: 85vh;
    }
    .footerTalk {
      grid-row: 1;
    }
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    .banner.bottom .awssld__wrapper {
      height: 300px;
    }
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    .banner.bottom .awssld__wrapper {
      height: 350px;
    }
  }	
  
  @media(min-width: 993px){
    .banner.bottom .awssld__wrapper {
      height: 300px;
    }
  }

  


`;