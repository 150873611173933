import React from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';

import { PropertyHit } from ".";
import { ImageHit } from "site/src/components"

import { Grid210 } from "site/src/components/css";

var _ = require('lodash');
var property = [];

const SearchResults = ({ hits, type, isSearchStalled }) => {

  var image = null;

  if (type === "ToLet") {
    property = Object.values(_.groupBy(hits, 'property_gmaven_key'));
  } else {
    property = hits;
  }

  //Loading
  if (isSearchStalled) {
    return (
      <div className="loader"></div>
    )
  }

  //Results
  if (property.length > 0 && !isSearchStalled) {

    return (

      <div>

        {
          property.map((hit, i) => {

            if (hit.length) {
              image = hit[0];
            } else {
              image = hit;
            }

            return (

              <Grid210 key={i}>

                <ImageHit title={image.property_name} data={image.property_images} best_image={image.best_image ? image.best_image : process.env.GATSBY_IMAGE_PLACEHOLDER} />

                <PropertyHit
                  hitData={hit}
                  type={type}
                  i={i}
                />

              </Grid210>

            )
          })
        }

      </div>

    )
  }

  //No results
  if (property.length === 0 && !isSearchStalled) {
    return "Ooops, we have no results that meet your search criteria. Please reset your filters and try again."
  }

}

export default connectHits(connectStateResults(SearchResults))