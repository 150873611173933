import styled from 'styled-components';

//20 52 26
export const Grid1 = styled.div`
  display: grid;
  max-width: 900px;
  margin: 1em auto;
  grid-column-gap: 5px;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 20% 50% 26%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }
`;

export const Grid11 = styled.div`
  display: grid;
  max-width: 1200px;
  margin: 0 auto;
  
  @media(max-width:1023px) {
    grid-template-columns: 100%;
    grid-row-gap: 1rem;
    align-items: start;
    .propertyTitle {
      grid-column: 1 / 1; 
    }
    .propertyDescription {
      grid-column: 1 / 1;
      grid-row: 3 / 3;
    }
    .propertyMap {
      grid-column: 1 / 1;
      grid-row: 4 / 4;
    }
    .propertyImages {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
    }
    .imageCategory {

    }
  }	

  
  @media(min-width: 1024px){
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    align-items: end;
    .propertyTitle {
      grid-column: 1 / 1; 
      grid-row: 2 / 2;
    }
    .propertyDescription {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
    }
    .propertyMap {
      grid-column: 1 / 1;
      grid-row: 3 / 3;
    }
    .propertyImages {
      grid-column: 2 / 2;
      grid-row: 2 / 4;
    }
    .imageCategory {

    }
  }
`;

export const Grid12 = styled.div`
  display: grid;
  max-width: 1200px;
  margin: 0 auto;
  
  @media(max-width:1023px) {
    grid-template-columns: 100%;
    grid-row-gap: 1rem;
    align-items: start;
    .propertyTitle {
      grid-column: 1 / 1; 
    }
    .propertyDescription {
      grid-column: 1 / 1;
      grid-row: 3 / 3;
    }
    .propertyMap {
      grid-column: 1 / 1;
      grid-row: 4 / 4;
    }
    .propertyImages {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
    }
  }	

  
  @media(min-width: 1024px){
    grid-template-columns: 35% 65%;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    align-items: end;
    .propertyTitle {
      grid-column: 1 / 1; 
    }
    .propertyDescription {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
    }
    .propertyMap {
      grid-column: 1 / 1;
      grid-row: 3 / 3;
    }
    .propertyImages {
      grid-column: 2 / 2;
      
    }
  }
`;

export const Grid13 = styled.div`
  display: grid;
  max-width: 1200px;
  margin: 3rem auto;
  
  @media(max-width:1023px) {
    grid-template-columns: 100%;
    grid-row-gap: 1rem;
    align-items: start;
    .propertyTitle {
      grid-column: 1 / 1; 
    }
    .propertyDescription {
      grid-column: 1 / 1;
      grid-row: 3 / 3;
    }
    .propertyButton {
      grid-column: 1 / 1;
      grid-row: 4 / 4;
    }
    .propertyImages {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
    }
  }	

  
  @media(min-width: 1024px){
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    align-items: end;
    .propertyTitle {
      grid-column: 2 / 2; 
    }
    .propertyDescription {
      grid-column: 2 / 2;
      grid-row: 2 / 2;
    }
    .propertyButton {
      grid-column: 2 / 2;
      grid-row: 3 / 3;
    }
    .propertyImages {
      grid-column: 1 / 1;
      grid-row: 1 / 4;
    }
  }
`;

//Half
export const Grid2 = styled.div`
  display: grid;
  max-width: 1200px;
  grid-row-gap: 1rem;

  @media(max-width:576px){
    grid-template-columns: 100%;

    .infoBlock {
      order: 2;
    }
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;

    .infoBlock {
      order: 2;
    }
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	

  img {
    width: 50%;
    object-fit: contain;
    object-position: top;
  }
`;

export const Grid21 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 1em auto;

  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	

  img {
    object-fit: contain!important;
    object-position: center;
    height: 90%!important;
  }
`;

export const Grid22 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  

  @media(max-width:576px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	

  img {
    width: 100%;
  }
  
`;

export const Grid23 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 1em auto;

  grid-template-columns: 10% 88%;
  grid-column-gap: 2%;

  @media(max-width:576px){ 
    grid-template-columns: 100%;
  
  }
  
`;

export const Grid24 = styled.div`
  display: grid;
  max-width: 1200px;
  margin: auto;

  @media(max-width:576px){
    grid-template-columns: 100%;

    .infoBlock {
      order: 2;
    }
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;

    .infoBlock {
      order: 2;
    }
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	

`;

export const Grid25 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 1em auto 0 auto;

  @media(max-width:320px){
    grid-template-columns: 69% 29%;
    grid-column-gap: 2%;
  }	

  @media(min-width: 321px) and (max-width:576px){
    grid-template-columns: 59% 39%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	

  img {
    width: 100%;
  }
  
`;

export const Grid26 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0;
  align-items: center;

  grid-template-columns: 75% 23%;
  grid-column-gap: 2%;
  
`;

export const Grid27 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  grid-template-columns: 50% 50%;
  align-items: center;
`;

export const Grid28 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  align-items: center;
  grid-gap: 1rem;
  align-items: start;

  @media(max-width:577px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 578px) {
    grid-template-columns: 2fr 3fr;
  }	
  
`;

export const Grid29 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  grid-template-columns: 50% 50%;

`;

export const Grid210 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 2rem;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 577px){
    grid-template-columns: 39% 59%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
`;

export const Grid211 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 1em auto 3rem auto;

  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 30% 66%;
    grid-column-gap: 4%;
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 20% 76%;
    grid-column-gap: 4%;
  }	
  
`;

export const Grid212 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 500px;
  margin: 0;
  grid-template-columns: 100%;
  grid-template-rows: 50% 15% 35%;
  overflow: hidden;

  a {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const Grid213 = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0;
  align-items: center;
  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	
`;

//Thirds
export const Grid3 = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 1em auto;

  .flatGrid {
    background-size: cover; 
    background-position: center;
    position: relative; 
    height: 400px;
  }

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }

  img {
    width: 100%;
    object-fit: contain;
    object-position: top;
    height: 200px;
  }
`;

export const Grid31 = styled.div`
  
  display: grid;
  max-width: 1200px;
  margin: 1em auto;

  .staggeredGrid {
    background-size: cover; 
    background-position: center;
    position: relative; 
    height: 400px;
  }

  .staggeredGrid:nth-child(3n+2), .staggeredGrid:nth-child(3n+2) {
    margin-top: 3rem;
  }

  .staggeredGrid:nth-child(3n+1), .staggeredGrid:nth-child(3n+3) {
    margin-bottom: 3rem;
  }

  

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 10px;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 1rem;

  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 1rem;

  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 1rem;
  }

  @media(max-width:992px){
    .staggeredGrid:nth-child(3n+2), .staggeredGrid:nth-child(3n+2) {
      margin-top: 0;
    }

    .staggeredGrid:nth-child(3n+1), .staggeredGrid:nth-child(3n+3) {
      margin-bottom: 0rem;
    }
  }

  img {
    width: 100%;
    object-fit: contain;
    object-position: top;
    height: 300px;
  }
`;

export const Grid32 = styled.div`
  display: grid;
  max-width: 1200px;
  margin: 1em auto;
  scroll-snap-type: y mandatory;

  @media(max-width:576px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 4%;
  }

  img {
    width: 100%;
    object-fit: contain;
    object-position: top;
    height: 200px;
  }
`;

export const Grid33 = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 1em auto;

  .flatGrid {
    background-size: cover; 
    background-position: center;
    position: relative; 
    height: 400px;
  }

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 0rem;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 100%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 64%;
    grid-column-gap: 4%;
    grid-row-gap: 2rem;
  }

`;

export const Grid34 = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 1em auto;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 0rem;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 100%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 993px) and (max-width: 1023px){
    grid-template-columns: 100%;
    grid-column-gap: 2%;
  }

  @media(min-width: 1024px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
  }


`;

export const Grid35 = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 1em auto;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 0rem;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 32% 64%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 64%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }

`;

export const Grid36 = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 1em auto;

  .flatGrid {
    background-size: cover; 
    background-position: center;
    position: relative; 
    height: 400px;
  }

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 0rem;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }

  img {
    width: 100%;
    object-fit: contain;
    object-position: top;
    height: 200px;
  }
`;

export const Grid37 = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 1em auto;

  @media(max-width: 768px){
    grid-template-columns: 80% 20%;
  }	
  
  @media(min-width: 769px){
    grid-template-columns: 40% 40% 20%;
  }

`;

export const Grid38 = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 1em auto;

  .flatGrid {
    background-size: cover; 
    background-position: center;
    position: relative; 
    height: 400px;
  }

  @media(max-width:576px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }

  img {
    width: 100%;
    object-fit: contain;
    object-position: top;
    height: 200px;
  }
`;

export const Grid39 = styled.div`
  display: grid;
  align-items: start;

  .b0 {

  }

  .b1 {

  }

  .b2 {

  }
  
  @media(min-width: 900px){
    grid-template-columns: 1fr 1fr 1fr;
    .a0 {
      grid-column: 1 / 1; 
      height: 100%;
    }
    .b0 {
      grid-column: 2 / 2; 
      height: 100%;
    }
    .a1 {
      grid-column: 3 / 3; 
      height: 100%;
    }
    .b1 {
      grid-column: 3 / 3;
      grid-row: 2 / 2; 
      height: 100%;
    }
    .a2 {
      grid-column: 2 / 2;
      grid-row: 2 / 2; 
      height: 100%;
    }
    .b2 {
      grid-column: 1 / 1;
      grid-row: 2 / 2; 
      height: 100%;
    }
    .a3 {
      grid-column: 1 / 1;
      grid-row: 3 / 3; 
      height: 100%;
    }
    .b3 {
      grid-column: 2 / 2;
      grid-row: 3 / 3; 
      height: 100%;
    }
    .a4 {
      grid-column: 3 / 3;
      grid-row: 3 / 3; 
      height: 100%;
    }
    .b4 {
      grid-column: 3 / 3;
      grid-row: 4 / 4; 
      height: 100%;
    }
    .a5 {
      grid-column: 2 / 2;
      grid-row: 4 / 4; 
      height: 100%;
    }
    .b5 {
      grid-column: 1 / 1;
      grid-row: 4 / 4; 
      height: 100%;
    }
    .a6 {
      grid-column: 1 / 1;
      grid-row: 5 / 5; 
      height: 100%;
    }
    .b6 {
      grid-column: 2 / 2;
      grid-row: 5 / 5; 
      height: 100%;
    }
    .a7 {
      grid-column: 3 / 3;
      grid-row: 5 / 5; 
      height: 100%;
    }
    .b7 {
      grid-column: 3 / 3;
      grid-row: 6 / 6; 
      height: 100%;
    }
  }
`;

export const Grid30 = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 1em auto;
  align-items: center;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2rem;
  }

  img {

  }
`;

//Quarters
export const Grid4 = styled.div`
  display: grid;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    grid-column-gap: 2%;
    grid-row-gap: 1%;
  }

  img {
    object-fit: contain;
    object-position: top;
    height: 300px;
  }
`;

export const Grid41 = styled.div`
  display: grid;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    grid-column-gap: 2%;
    grid-row-gap: 1%;
  }

  img {
    object-fit: contain;
    object-position: top;
    height: 300px;
  }
`;

//
export const Grid5 = styled.div`
  display: grid;
  max-width: 900px;
  margin: 1em auto;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 15% 39% 30% 10%;
    grid-column-gap: 2%;
    grid-row-gap: 1%;
  }

  img {
    object-fit: contain;
    object-position: top;
    height: 300px;
  }
`;

export const Grid6 = styled.div`
  display: grid;
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  grid-template-columns: 90% 10%;

`;

export const Grid61 = styled.div`
  display: grid;
  grid-template-columns: 15% 84%;
  grid-column-gap: 1%;
  grid-row-gap: 1%;

  .itemCount {
    font-style: italic;
    display: inline-block;
    margin: 0;
    position: relative;
    font-weight: normal;
    font-size: 1rem;
  }

  .itemCount:after {
    content:"";
    position:absolute;
    top: 10px;
    right: -35px;
    width: 40px;
    transform: rotate(135deg);
    transform-origin: 0% 0%;
    border-top: 1px solid orange;
  }

`;

export const Grid62 = styled.div`
  display: grid;
  grid-template-columns: 25% 64%;
  grid-column-gap: 1%;

`;

export const Grid63 = styled.div`
  display: grid;
  align-items: start;
  margin: 3em auto 0 auto;
  padding: 1rem 3rem;
  background: #eee;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 0rem;
    padding: 1rem;
  }	
  
  @media(min-width: 577px){
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
  }	
  
`

export const Grid64 = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 10px;

`;