import styled from 'styled-components';

export const StyledTimeline = styled.div`

  h1 {
    text-align: right;
    text-transform: none;
    margin-top: 0;
  }

  h2 {
    font-size: 3rem;
    margin: 0;
    font-weight: 300;
  }

  h3 {
    text-align: right;
    font-weight: 300;
    margin-bottom: 0;
  }

  h4 {

  }

  

  .content.date {
    max-width: 300px;
  }

  .para_right {
    position: absolute;
    text-align: left;
    margin-left: 2rem;
  }

  .para_left {
    text-align: left;
    margin-right: 2rem;
  }

  img {
    max-width: 100%;
  }

  .img_left {
    padding-right: 2rem;
  }

  .img_right {
    padding-left: 2rem;
  }

  .left.inline_row {
    float: left;
  }

  .inline_row {
    display: inline-block;
  }

  .square_box::after {
    background: white;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .square_box.border::after {
    border: 3px solid black;
  }

  .middle_content {
    position: relative;
    z-index: 2;
    display: inline-table;
    text-align: center;
    width: 100%;
  }

  .inline {
    display: inline-block;
  }

  /* The actual timeline (the vertical ruler) */
  .timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  /* Top cross line */
  .top_bar {
    width: 20px;
    height: 3px;
    background: black;
    position: absolute;
    left: calc(50% - 11px);
  }

  .timeline::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: black;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }

  /* Container around content */
  .container {
    //padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }

  /* The circles on the timeline */
  .container::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    right: -0px;
    background-color: black;
    border: 4px solid #000000;
    top: 15px;
    z-index: 1;
    border-radius: 50%;
  }

  .container.none::after {
    display: none;
  }

  .container.left::after, .container.right.none::after {
    
  }

  /* Place the container to the left */
  .left {
    left: 0;
  }

  /* Place the container to the right */
  .right {
    left: 50%;
  }

  /* Place the container in the middle */
  .container.middle {
    margin: auto;
    overflow: hidden;
  }

  .middle .content {
    margin-left: auto;
    margin-right: auto;
  }

  

  

  /* Fix the circle for containers on the right side */
  .right::after {
    left: -11px;
    border: solid white 5px;
  }

  .left::after {
    right: -9px;
    border: solid white 5px;
  }

  /* The actual content */
  .content {
    margin-top: 6rem;
    //padding: 20px 30px;
    padding: 1rem;
    position: relative;
    border-radius: 6px;
  }

  .content_blocks {
    padding: 1rem;
  }

  .left_side {
    margin-left: auto;
    max-width: 350px;
  }

  /* Media queries - Responsive timeline on screens less than 600px wide */

  @media (max-width: 600px){
    .container {
      width: 95%;
    }
    .container.right, .container.left {
      width: 50%;
    }
  }
  
`;