import { createGlobalStyle } from 'styled-components';
import 'react-awesome-slider/dist/styles.css';

export const GlobalStyles = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.primaryWhite};
    color: ${({ theme }) => theme.primaryFont};
    font-family: ${({ theme }) => theme.secondaryTypeface};
    font-weight: 300;
  }

  body {
    margin: 0;
    width: 100%;
    justify-content: center;
    text-rendering: optimizeLegibility;
  }

  *, *::after, *::before {
    box-sizing: border-box;
    
  }

  div#gatsby-focus-wrapper {
    background:  ${({ theme }) => theme.primaryWhite};
  }

  textarea, input {
    font-family: ${({ theme }) => theme.secondaryTypeface};
    font-size: 1rem;
    font-weight: 300;
  }

  div#___gatsby, div#gatsby-focus-wrapper {
    min-height: 100vh;
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme }) => theme.primaryFont};
  }

  h1 {
    font-size: 3rem;
    font-family: ${({ theme }) => theme.secondaryTypeface};
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  h2 {
    font-size: 1.8rem;
    font-family: ${({ theme }) => theme.secondaryTypeface};
    font-weight: 600;
    letter-spacing: 1px;
  }

  h3 {
    font-size: 1.75rem;
    margin: 1em 0;
    font-family: ${({ theme }) => theme.secondaryTypeface};
    font-weight: 600;
    letter-spacing: 1px;
  }

  h4 {
    font-size: 1.5rem;
    font-family: ${({ theme }) => theme.secondaryTypeface};
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  h5 {
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.secondaryTypeface};
    font-weight: 600;
    letter-spacing: 1px;
    margin: 1.5rem 0 1rem 0;
  }

  h6 {
    font-size: 0.8rem;
    font-weight: 600;
    margin: 3px 0;
    font-family: ${({ theme }) => theme.secondaryTypeface};
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000000;
  }

  p {
    font-size: 0.9rem;
    margin: 0.4rem 0;
    letter-spacing: 0.7px;
    line-height: 1.3rem;
  }

  hr {
    width: 1rem;
    margin-top: 1.5rem;
    margin-right: auto;
    margin-left: 0;
    height: 0.2rem;
    background: ${({ theme }) => theme.primaryDark};
    border: none;
  }

  b {
    font-family: ${({ theme }) => theme.secondaryTypeface};
    font-weight: 600;
  }

  .md-form {
    margin: 0;
  }


  .filterToggle, .filterToggleClose {
    border: none;
  }
  
  .filterToggleClose {
    display: block;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryWhite};
    width: 30px;
    height: 30px;
    padding: 0;
    margin-left: auto;
  }

  .socialIcon svg {
    width: 3rem;
    height: 3rem;
    margin: 0rem 1rem 1rem 0; 
    
  }

  .socials svg {
    &:hover {
      color: #d61f27;
    }
  }

  button {
    &:focus {
      outline: none;
    }
  }

  @media(max-width:576px){
    h1 {
      font-size: 1.8rem;
    }
  
    h2 {
      font-size: 1.6rem;
    }
  
    h3 {
      font-size: 1.4rem;
    }
  
    h4 {
      font-size: 1.5rem;

    }

    h5 {
      font-size: 1rem;
    }
  
    h6 {
      font-size: 0.8rem;
    }
  
    p {
      font-size: 0.9rem;
    }

    .desktop {
      display: none!important;
    }
    .mobile {
      display: inherit!important;
    }
  }	
  
  .mobile {
    display: none;
    height: auto;
    object-fit: contain;
  }

  .pagePadding {
    padding: 1rem;
  }

  @media(max-width: 667px){
    .pagePadding {
      padding: 0;
    }
  }

  button.image-gallery-bullet {
    padding: 4px;
    border: none;
    background: grey;
    box-shadow: none;
    &:hover {
      background: ${({ theme }) => theme.primaryWhite };
    }
  }
  
  .buttonNav {
    position: absolute;
    top: 45%;
    z-index: 999999;
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    &:hover {
      color: ${({ theme }) => theme.primaryDark };
      cursor: pointer;
    }
  }

  .right {
    right: 0;
  }

  .prevNextIcon svg {
    vertical-align: text-bottom;
  }

  .basicButton {
    padding: 0.5rem 1rem;
    background: white;
    border: 1px solid #eee;
    font-size: 1rem;
    color: #96979a;

    &:hover {
      cursor: pointer;
      background: #96979a;
      color: ${({ theme }) => theme.primaryWhite};
      border: 1px solid #eee;
    }
  }
  
`